import React from "react"
import BookletLayout from "../layouts/BookletLayout"
import { makeStyles } from "@material-ui/core"

import SEO from "../components/seo"

const useStyles = makeStyles({
  wrapper: {
    display: "flex",
    flexDirection: "column",
    height: "100%"
  }
})

const Calendar = () => {
  const classes = useStyles()

  return (
    <BookletLayout>
      <SEO title="Calendar" />
      <div className={classes.wrapper}>
        <h1>Calendar</h1>
        <iframe
          src="https://calendar.google.com/calendar/embed?src=44gla2gm5bj5n48sdnb41rngso%40group.calendar.google.com&ctz=Europe%2FLondon"
          style={{ border: 0, flex: 1 }}
          width="100%"
          frameborder="0"
          scrolling="no"
        ></iframe>
      </div>
    </BookletLayout>
  )
}

export default Calendar
